import React, { useEffect, useRef, useState } from 'react';
// import ReactSwipe from 'react-swipe';

import '../styles/modal.css';
import classes from "../styles/modal.module.css"
import { func } from 'prop-types';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Draggable, {DraggableCore} from 'react-draggable'
import current_logo from "../images/current.png"
import ApexCharts from 'apexcharts'
import img_arrow from "../images/arrow.png"
import close_icon from "../images/close.png"
import Slider from '@mui/material/Slider';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

var ru = require("apexcharts/dist/locales/ru.json")

const Modal = ({modal_status, set_status, info, Items, SetItems}) => {
    const navigate = useNavigate();
    let location = useLocation();

    const [price, Setprice] = useState("0$")
    const [border, Setborder] = useState("0$")
    const [price_flag, Setprice_flag] = useState(false)
    const [border_Flag, Setborder_flag] = useState(false)
    const [price_percent, Setprice_percent] = useState(0)
    const [border_percent, Setborder_percent] = useState(0)
    const [ChartTime, SetChartTime] = useState(0)
    const [SliderValue, SetSliderValue] = useState(0)
    const [Prices, SetPrices] = useState({items_USD: [], items_RUB: []})
    // const [Prices_USD, SetPrices_USD] = useState([])
    // const [Prices_RUB, SetPrices_RUB] = useState([])
    let if_finger_in_chart = false

    let item = {name: "", lowest_price_USD: 0, item_local_id: 0, lowest_price_RUB: 0, border_price: 0, price_USD: 0, zero_price: 0, img: "https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17PLfYQJK9cyzhr-JkvbnJ4Tck29Y_cg_ieuUoI7x0QfsqkE9ajr3cYTEIVBvZVmGrlS-l728gZfuupnOnyQ36z5iuyh_bCiqzQ", color: [0, 0, 0]};
    info.items.forEach(element => {
        if (element.id == info.id) {
            item = element;
        }
    });
    
    console.log("info", info);
    
    function item_price(price, func1, func2, type) {
        var regex = /^(|0\.?[1-9]{0,3}|[1-9]{1,4}\.?[0-9]{0,3}|[1-9][0-9]{0,4}\.?[0-9]{0,3})\$$/
        price = price.replace(",", ".");
        if (!(String(price).includes("$"))) {
            price = price.slice(0, -1) + "$";
        } else {
            if (price.slice(-1) !== "$") {
                price = price.replace("$", "") + "$";
            }
        }
        if (!regex.test(price)) {
            return;
        }
        // console.log(price, regex.test(price));
        
        if (type === "price") Setprice_flag(true);
        if (type === "border") Setborder_flag(true);

        func1(price)
        func2((Number(price.replace("$", "")) * 100 / item.zero_price - 100).toFixed(2))
        // console.log(func);
    }

    const [Chart, SetChart] = useState("")
    useEffect (() => {
        item_price(`${item.price_USD}$`, Setprice, Setprice_percent, "price");
        item_price(`${item.border_price}$`, Setborder, Setborder_percent, "border");
        SetSliderValue(item.border_price)
        Setprice_flag(false);
        Setborder_flag(false);

        async function LoadSales() {
            // console.log("clearing")
            console.log(item)
            Chart.updateSeries([{name: "Цена", data: []}]);
            SetChartTime(0);
            const response = await fetch(`https://artempos.ru/api/get_item_sales?key=${Cookies.get("session")}&id=${info.id}`);
            let answer = await response.json();
            // console.log(document.querySelector(`.${classes.name}`).textContent)
            if (answer.status) {
                // console.log(item.name, answer.name)
                if (document.querySelector(`.${classes.name}`).textContent != answer.name) {
                    return;
                }

                let data = answer.sales.map((sale) => {
                    return {x: new Date(sale[0]).getTime(),y: sale[1]}
                })
                console.log(data)
                let options = {
                    colors: [`rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`],
                    markers: {
                        colors: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`
                    }}
                // console.log("drawing", status);
                Chart.updateSeries([{name: "Цена", data: data}])        
                Chart.updateOptions(options)     
                let time = Date.now()/1000 - answer.timestamp
                if (time < 0) {
                    time = 0;
                }
                let days = Math.floor(time / 60 / 60 / 24)
                let hours = Math.floor(time / 60 / 60)
                let minutes = Math.floor(time / 60)
                if (days !== 0) {
                    if (days === 11 || days === 12 || days === 13 || days === 14) SetChartTime(`${days} дней`)
                    else if (days % 10 === 1) SetChartTime(`${days} день`)
                    else if (days % 10 === 2 || days % 10 === 3 || days % 10 === 4) SetChartTime(`${days} дня`)
                    else SetChartTime(`${days} дней`)
                } else if (hours !== 0) {
                    if (hours === 11 || hours === 12 || hours === 13 || hours === 14) SetChartTime(`${hours} часов`)
                    else if (hours % 10 === 1) SetChartTime(`${hours} час`)
                    else if (hours % 10 === 2 || hours % 10 === 3 || hours % 10 === 4) SetChartTime(`${hours} часа`)
                    else SetChartTime(`${hours} часов`)
                } else if (minutes !== 0) {
                    if (minutes === 11 || minutes === 12 || minutes === 13 || minutes === 14) SetChartTime(`${minutes} минут`)
                    else if (minutes % 10 === 1) SetChartTime(`${minutes} минута`)
                    else if (minutes % 10 === 2 || minutes % 10 === 3 || minutes % 10 === 4) SetChartTime(`${minutes} минуты`)
                    else SetChartTime(`${minutes} минут`)
                } else {
                    SetChartTime("Сейчас")
                }
            } else {
                if (answer.error === "wrong session") {
                    navigate('/login', { replace: true });
                } else {
                    toast.error(answer.error)
                }
            }
        }
        async function LoadPrices() {
            const response = await fetch(`https://artempos.ru/api/get_item_prices?key=${Cookies.get("session")}&id=${info.id}&item_local_id=${item.item_local_id}`);
            let answer = await response.json();
            // console.log(document.querySelector(`.${classes.name}`).textContent)
            if (answer.status) {
                if (document.querySelector(`.${classes.name}`).textContent != answer.name) {
                    return;
                }
                SetPrices({items_USD: answer.items_USD, items_RUB: answer.items_RUB});
            } else {
                if (answer.error === "wrong session") {
                    navigate('/login', { replace: true });
                } else {
                    toast.error(answer.error)
                }
            }
        }
        if (info.id === -1) {
            
        }
        else if (modal_status && info.id !== 0) {
            LoadSales();
            LoadPrices();
            SetPricesSlider({...PricesSlider, id: 1})
        }
        if (!modal_status && info.id !== -1) {
            // console.log("clearing")
            Chart.updateSeries([{name: "Цена", data: []}]);
            SetChartTime(0);
            document.querySelector(`[class='${classes.top}']`).style.removeProperty("min-height");
            document.querySelector(`.${classes.module_opener}`).classList.toggle("open");
            SetPrices({items_USD: [], items_RUB: []});
        }
    }, [modal_status, info])

    async function api_set_price() {
        if (price_flag === false && border_Flag === false) {
            toast("Нет внесенных изменений", {theme: "colored"})
        } else{
            let flag1 = true;
            if (price_flag) flag1 = false
            let flag2 = true;
            if (border_Flag) flag2 = false

            if (border_Flag){
                if (border.replace("$", "") === "") {
                    toast.error("Порог не может быть пустым");
                    return;
                }

                const response = await fetch(`https://artempos.ru/api/set_price?key=${Cookies.get("session")}&id=${info.id}&type=border&price=${border.replace("$", "")}`);
                let answer = await response.json();
                if (answer.status) {
                    toast.success(answer.answer)
                    flag2 = true;
                    Setborder_flag(false);
                    item.border_price = Number(border.replace("$", ""));
                    let copy = {...Items};
                    copy[info.user_id].items.map((item) => {
                        if (item.id === info.id) {
                            item.border_price = Number(border.replace("$", ""));
                        }
                    })
                    SetItems(copy);
                } else {
                    if (answer.error === "wrong session") {
                        navigate('/login', { replace: true });
                    } else {
                        toast.error(answer.error)
                    }
                }
            }
            if (price_flag){
                if (price.replace("$", "") === "") {
                    toast.error("Цена не может быть пустая");
                    return;
                }
                

                const response = await fetch(`https://artempos.ru/api/set_price?key=${Cookies.get("session")}&id=${info.id}&type=price&price=${price.replace("$", "")}`);
                let answer = await response.json();
                if (answer.status) {
                    toast.success(answer.answer)
                    flag1 = true;
                    Setprice_flag(false);
                    item.price_USD = Number(price.replace("$", ""))
                    let copy = {...Items};
                    copy[info.user_id].items.map((item) => {
                        if (item.id === info.id) {
                            item.price_USD = Number(price.replace("$", ""));
                        }
                    })
                    SetItems(copy);
                } else {
                    if (answer.error === "wrong session") {
                        navigate('/login', { replace: true });
                    } else {
                        toast.error(answer.error)
                    }
                }
            }
            if (flag1 && flag2){
                Modal_Close();
            }
            
        }
    }

    const [PricesSlider, SetPricesSlider] = useState({id: -1, count: 0, info: {USD: [], RUB: [], percent: [], positions: []}});

    useEffect(() => {
        async function LoadPrices() {
            console.log(1, PricesSlider.id, PricesSlider.count)
            if (PricesSlider.id === -1) {
                return;
            }
            
            // console.log(item.name, answer.name)
            let items = {}
            let prices_USD = []
            let prices_RUB = []
            let prices_percent = [];
            let prices_positions = [];
            let count = 0;
            
            Prices.items_USD.map((t_item) => {
                count += 1;
                if (t_item.id == item.id) {
                    items[count] = {usd_id: 0, rub_id: 0, usd:0, rub: 0, percent: 0}
                }
            })
            count = 0;
            Prices.items_RUB.map((t_item) => {
                count += 1;
                if (t_item.id == item.id) {
                    if (!(count in items)) {
                        items[count] = {usd_id: 0, rub_id: 0, usd:0, rub: 0, percent: 0}
                    }
                }
                if (count in items) {
                    items[count].rub = t_item.price/100
                    items[count].rub_id = t_item.id
                }
            })
            if (PricesSlider.id === 2) {
                console.log("qweqwe")
                count = 0;
                Prices.items_RUB.map((t_item) => {
                    count += 1;
                    if (Object.keys(items).length < 5 ) {
                        items[count] = {usd_id: t_item.id, rub_id: t_item.id, usd:0, rub: t_item.price/100, percent: 0}
                    }
                })
            } else if (PricesSlider.id === 1 || PricesSlider.id === 3) {
                count = 0;
                Prices.items_RUB.map((t_item) => {
                    count += 1;
                    items[count] = {usd_id: t_item.id, rub_id: t_item.id, usd:0, rub: t_item.price/100, percent: 0}
                })
            }
            count = 0;
            Prices.items_USD.map((t_item) => {
                count += 1;
                if (count in items) {
                    items[count].usd = t_item.price/1000
                    items[count].percent = (t_item.price/1000*100/item.zero_price-100).toFixed(2)
                    items[count].usd_id = t_item.id
                }
            })

            console.log(items)

            count = 0;
            Object.keys(items).map((key) => {
                count += 1;
                let element = items[key];
                
                if (element.usd_id != item.id) {
                    // SetPrices_USD(prices => [...prices, <div key={count}>{`${element.usd.toFixed(3)}$`}</div>])
                    prices_USD.push(<div key={count}>{`${element.usd.toFixed(3)}$`}</div>);
                } else {
                    // SetPrices_USD(prices => [...prices, <div key={count} className={classes.current} style={{color: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}><div src={current_logo} style={{left: `calc(50% - 14px - ${(element.usd.toFixed(3).toString().replace(".", "").length)*5.6}px)`, zIndex: 1, backgroundColor: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}></div>{`${element.usd.toFixed(3)}$`}</div>])
                    prices_USD.push(<div key={count} className={classes.current} style={{color: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}><div src={current_logo} style={{left: `calc(50% - 14px - ${(element.usd.toFixed(3).toString().replace(".", "").length)*5.6}px)`, zIndex: 1, backgroundColor: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}></div>{`${element.usd.toFixed(3)}$`}</div>);
                }
                if (element.rub_id != item.id) {
                    // SetPrices_RUB(prices => [...prices, <div key={count}>{`${element.rub.toFixed(2)}₽`}</div>])
                    prices_RUB.push(<div key={count}>{`${element.rub.toFixed(2)}₽`}</div>);
                } else {
                    // SetPrices_RUB(prices => [...prices, <div key={count} className={classes.current} style={{color: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}><div src={current_logo} style={{left: `calc(50% - 14px - ${(element.rub.toFixed(2).toString().replace(".", "").length)*5.6}px)`, zIndex: 1, backgroundColor: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}></div>{`${element.rub.toFixed(2)}₽`}</div>])
                    prices_RUB.push(<div key={count} className={classes.current} style={{color: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}><div src={current_logo} style={{left: `calc(50% - 14px - ${(element.rub.toFixed(2).toString().replace(".", "").length)*5.6}px)`, zIndex: 1, backgroundColor: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}></div>{`${element.rub.toFixed(2)}₽`}</div>);
                }
                prices_percent.push(<div key={count}>{`${(element.usd*100/item.zero_price-100).toFixed(2)}%`}</div>);
                prices_positions.push(<div key={count}>{key}</div>);
            });
            console.log(2, PricesSlider.id, PricesSlider.count)
            SetPricesSlider({...PricesSlider, count: count, info: {USD: prices_USD, RUB: prices_RUB, percent: prices_percent, positions: prices_positions}})
        }
        LoadPrices();
    }, [PricesSlider.id, Prices])

    let top_space = 0
    let last_touch = 0;
    let move_status = false;
    let move_position = 0;
    // const [MouseDown, SetMouseDown] = useState(false)
    const [FingerDown, SetFingerDown] = useState(false)

    function Change_Final_Position() {
        SetFingerDown(true)

        if (move_status === true && move_position >= 80) {
            Modal_Close();
        } else {
            document.querySelector(".modal_content").setAttribute("style","transition: 0.3s");
            setTimeout(() => {document.querySelector(".modal_content").style.removeProperty("transition");}, 300);
            move_position = 0;
            document.querySelector(".modal_content").style.transform = `translateY(${move_position}px)`;
        }
    }
    function Change_Position(e) {
        if (if_finger_in_chart) {
            e.preventDefault();
            return;
        }

        if (last_touch === 0) {
            last_touch = e.changedTouches[0].screenY;
        }

        if (move_status === true) {
            // console.log("down");
            e.preventDefault()
            if (top_space >= 0 && move_position === 0 && last_touch > e.changedTouches[0].screenY) {
                // console.log(1);
                move_status = false
                // document.querySelector(".modal_content").style.overscrollBehavior = "auto";            
            } else {
                console.log(2);
                move_position = move_position + (e.changedTouches[0].screenY-last_touch);
                if (move_position < 0) {
                    move_position = 0
                }
                document.querySelector(".modal_content").style.transform = `translateY(${move_position}px)`;            
                // document.querySelector(".modal_content").style.overscrollBehavior = "none";            
            }
        } else {
            // console.log(e.changedTouches[0].screenY, last_touch, move_position, top_space)
            if (top_space <= 0 && last_touch < e.changedTouches[0].screenY) {
                // console.log(3);
                // console.log("down");
                move_status = true;
            } else {
                // console.log(4);
                move_status = false;
            }
        }       
        
        last_touch = e.changedTouches[0].screenY;
    }
    function Scroll_Check(e) {
        top_space = e.currentTarget.scrollTop;
        if (move_status) {e.currentTarget.scrollTop = 0};
    }
    function Modal_Close() {
        document.querySelector(".modal_content").setAttribute("style","transition: 0.3s");
        setTimeout(() => {set_status(false);document.querySelector(".modal_content").style.removeProperty("transition");move_position=0;document.body.style.overflow = 'auto';info.id = 0;}, 300);
        setTimeout(() => {document.querySelector(".modal_content").style.transform = `translateY(0px)`;}, 500);
        move_status = false; 
        document.querySelector(".modal_content").style.transform = `translateY(110%)`;
        document.querySelector(".modal").classList.add("close");
        SetPricesSlider({...PricesSlider, id: -1});
        SetPrices({items_USD: [], items_RUB: []})
        document.querySelector(".modal_content").scrollTop = 0;
    }

    const ref = useRef(null);

    useEffect(() => {
        document.querySelector("#chart_sale_statistic").addEventListener('touchstart', function() {if_finger_in_chart = true});
        document.querySelector(".MuiSlider-root").addEventListener('touchstart', function() {if_finger_in_chart = true});
        // document.addEventListener('keydown', (e) => {
        //     if (location.pathname === "/") console.log(window.location.href);
        // }, false);
        const element = ref.current;
        element.addEventListener('touchstart', function (e) {last_touch=e.changedTouches[0].screenY;SetFingerDown(true);});
        element.addEventListener('touchend', function() {Change_Final_Position()});
        document.body.addEventListener('touchend', function() {if_finger_in_chart = false});
        element.addEventListener('touchmove', Change_Position, {passive: false});
        element.addEventListener('scroll', Scroll_Check, {passive: false});

        let options = {
            chart: {type: 'line', zoom: {enabled: false}, locales: [ru], defaultLocale: 'ru', toolbar: {show: false}},
            series: [{name: "Цена", data: []}],
            stroke: {color: '#e7e7e7', width: 2},
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return Math.round(value * 1000) / 1000 + "$";
                    },
                    style: {    
                        colors: "rgb(207, 207, 207)"
                    }
                },
                tickAmount: 10
            },
            xaxis: {
                labels: {   
                    style: {
                        colors: "rgb(207, 207, 207)"
                    },
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: 'd MMM',
                        day: 'd MMM',
                        hour: 'HH:mm'
                    }
                },
                type: 'datetime',
                tooltip: {
                    enabled: false
                }
            },
            tooltip: {
                x: {
                    format: "MMM d H:mm"
                },
                marker: {
                    show: false
                },
                style: {
                    color: "rgb(207, 207, 207)"
                }
            },
            grid: {
                borderColor: 'rgb(107, 107, 107)',
            },
            markers: {
                size: 2
            }
        }
        let chart = new ApexCharts(document.querySelector("#chart_sale_statistic"), options);
        SetChart(chart)
        chart.render();
    }, []);

    function ModalSlider(e) {
        // let id = document.querySelector(`[class='${classes.top}']`).getAttribute("id");
        if (PricesSlider.id === 1) {
            SetPricesSlider({...PricesSlider, id: 2});
            document.querySelector(`[class='${classes.top}']`).setAttribute("id", "2");
        } else if (PricesSlider.id === 2) {
            SetPricesSlider({...PricesSlider, id: 3});
            document.querySelector(`[class='${classes.top}']`).setAttribute("id", "2");
        } else {
            SetPricesSlider({...PricesSlider, id: 1});
            document.querySelector(`[class='${classes.top}']`).style.removeProperty("min-height");
            document.querySelector(`[class='${classes.top}']`).setAttribute("id", "1");
        }
        document.querySelector(`.${classes.module_opener}`).classList.toggle("open")
    }
    return (
        <div className={["modal", !modal_status ? " hide" : ""].join("")} onClick={(e, ui) => {
            Modal_Close();
        }}
        >
            <div className='modal_content' ref={ref}
                onClick={(e) => {
                    if (e.currentTarget.className === "modal_content") {
                        e.stopPropagation();
                    }
                }}
                // onMouseDown={(e) => {SetMouseDown(true)}}
                // onMouseUp={(e) => {SetMouseDown(false)}}
                // onMouseMove={(e) => {
                //     if (MouseDown) {
                //         if (Last_touch < e.screenY) {
                //             console.log("down", Top_space);
                //             if (Top_space === 0 && Modal_Position.status === false) {
                //                 SetModal_Position({status: true, position: 0})
                //                 // console.log(e.changedTouches[0].screenY);
                //             } else if (Top_space === 0 && Modal_Position.status === true) {
                //                 SetModal_Position({status: true, position: Modal_Position.position + (Last_touch-e.screenY)})
                //             }
                //         } else if (Last_touch > e.screenY) {
                //             // console.log("up", Top_space);
                //             if (Modal_Position.position !== 0 && Modal_Position.status === true) {
                //                 SetModal_Position({status: true, position: Modal_Position.position + (Last_touch-e.screenY)})
                //             }
                //         }
                        
                //         SetLast_touch(e.screenY);
                //         console.log(Modal_Position, Top_space);
                //     }
                // }}

                // onScroll={(e) => {Scroll_Check(e)}}
                // onTouchMove={(e) => {Change_Position(e)}}
                // onTouchEnd={(e) => {Change_Final_Position(Modal_Position)}}
                >
                
                <div className={classes.header}>
                    <img src={item.img} draggable={false} className={classes.header_item}></img>
                    <p className={classes.name} onClick={() => {navigator.clipboard.writeText(item.name);toast.info("Название скопировано")}}>{item.name}</p>
                    <img src={close_icon} className={classes.close_modal} onClick={Modal_Close}></img>
                </div>
                <div className={classes.prices}>
                    <div className={classes.price} onKeyDown={(e) => e.key === "Enter" ? api_set_price() : "" } style={{border: `1px solid rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}>
                        <div className={classes.price_percent} style={{color: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}>Проц: {price_percent}%</div>
                        <input inputMode="decimal" value={`${price}`} onChange={(e) => {item_price(e.target.value, Setprice, Setprice_percent, "price");}}/>
                        <p>Цена:</p>
                    </div>
                    <div className={[classes.price, classes.price2].join(" ")} onKeyDown={(e) => e.key === "Enter" ? api_set_price() : "" } style={{border: `1px solid rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}><div className={classes.price_percent} style={{color: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}>Проц: {border_percent}%</div><input inputMode="decimal" value={`${border}`} onChange={(e) => {item_price(e.target.value, Setborder, Setborder_percent, "border");}}/><p>Порог:</p></div>    
                    <Slider 
                        aria-label="Border"
                        value={SliderValue}
                        valueLabelDisplay="auto"
                        step={1}
                        onChange={(e) => {SetSliderValue(e.target.value);item_price(`${e.target.value}$`, Setborder, Setborder_percent, "border")}}
                        marks={[
                            {value: Math.round(item.border_price)-19, label: `${Math.round(item.border_price)-19}`},
                            {value: item.border_price, label: `${item.border_price}`},
                            {value: Math.round(item.border_price)+9, label: `${Math.round(item.border_price)+9}`},
                            // {value: item.zero_price, label: `${item.zero_price}`}
                        ]}
                        min={Math.round(item.border_price)-20}
                        max={Math.round(item.border_price)+10}
                        sx={{
                            // .css-eg0mwd-MuiSlider-thumb.Mui-active
                            color: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`,
                            '& .MuiSlider-thumb:hover': {
                                boxShadow: `0px 0px 0px 8px rgba(${item.color[0]}, ${item.color[1]}, ${item.color[2]}, 0.16)`,    
                            },
                            '& .MuiSlider-thumb.Mui-active': {
                                boxShadow: `0px 0px 0px 14px rgba(${item.color[0]}, ${item.color[1]}, ${item.color[2]}, 0.16)`,
                            },
                            '& .Mui-focusVisible': {
                                boxShadow: `0px 0px 0px 8px rgba(${item.color[0]}, ${item.color[1]}, ${item.color[2]}, 0.16)`,    
                            },
                            '& .MuiSlider-markLabel': {
                                color: "rgb(207, 207, 207)"                            },

                        }}
                        
                        style={{margin: "0px 0px 15px 0px"}} 
                    />
                    <div className={classes.confirm_prices} onMouseEnter={(e) => {e.target.style.backgroundColor = `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}} onMouseLeave={(e) => {document.querySelector(`[class='${classes.confirm_prices}']`).style.background = `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]}, 0.4)`}} onClick={api_set_price} style={{border: `1px solid rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`, backgroundColor: `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]}, 0.4)`}}>Подтвердить изменения</div>
                </div>
                <div className={classes.currency} >Валюта: USD</div>
                <div className={classes.top} id="1" onClick={(e) => {ModalSlider(e)}} style={PricesSlider.id !== 1 ? {minHeight: `${(PricesSlider.count+3)*22-42}px`} : {}}>
                    <div className={classes.module_opener}>{PricesSlider.id === 3 ? "Меньше" : "Больше"}{PricesSlider.id === 3 ? <img src={img_arrow} /> : <img src={img_arrow} style={{transform:"rotate(180deg)"}} ></img>}</div>
                    <div className={classes.column} style={{width: "16%"}}><div style={{margin: "2px 0 4px 0", fontSize: 18}}>№ поз.</div>{PricesSlider.info.positions}</div>
                    <div className={classes.column}><div style={{margin: "2px 0 4px 0", fontSize: 18}}>Цены $</div>{PricesSlider.info.USD}</div>
                    <div className={classes.column}><div style={{margin: "2px 0 4px 0", fontSize: 18}}>Цены ₽</div>{PricesSlider.info.RUB}</div>
                    <div className={classes.column}><div style={{margin: "2px 0 4px 0", fontSize: 18}}>Процент</div>{PricesSlider.info.percent}</div>
                </div>
                <div id='chart_sale_statistic' className={classes.chart}></div>
                <div className='chart_time'>Актуальность данных:&nbsp;<span style={ChartTime !== "Сейчас" ? {color: "Red"} : {}}>{ChartTime === 0 ? "" : ChartTime}</span></div>
            </div>          
        </div>
    )

    
}                                
export default Modal;